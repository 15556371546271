@use '../utilities' as *;

html{
    font-size: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*, *::after, *::before{
    box-sizing: inherit;
}

body{
    font-family: $sanSerifFont;
}