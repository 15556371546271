
.hero{

    &__button{

        &--group{
            display: flex;
            flex-direction: column;
            flex-basis: 1;
        }
    }

}